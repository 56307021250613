import React, { Component, Suspense, lazy } from 'react';
import Header from 'components/dumb/Header/Header';
import Init from 'components/smart/App/Init';
import Content from 'components/dumb/Content/Content';
import Loading from 'components/smart/Loading/Loading';
import Run from 'components/smart/Run/Run';
import Menu from 'components/smart/Menu/Menu';
import Dialog from 'components/smart/Dialog/Dialog';
import { GlobalHotKeys } from "react-hotkeys";
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import './App.scss';

// Dynamic imports for code-splitting
const Home = lazy(() => import('components/smart/home/Home'));
const Tasks = lazy(() => import('components/smart/tasks/Tasks'));
const Add = lazy(() => import('components/smart/add/Add'));
const Search = lazy(() => import('components/smart/search/Search'));
const Filter = lazy(() => import('components/smart/filters/Filter'));
const Profile = lazy(() => import('components/smart/profile/Profile'));
const ProfilePassword = lazy(() => import('components/smart/profile/password/ProfilePassword'));
const CustomersList = lazy(() => import('components/smart/customers/CustomersList'));
const RemovedCustomersList = lazy(() => import('components/smart/removedcustomers/RemovedCustomersList'));
const AlertsList = lazy(() => import('components/smart/alerts/AlertsList'));
const QueriesList = lazy(() => import('components/smart/queries/QueriesList'));
const WhistleblowersList = lazy(() => import('components/smart/whistleblowers/WhistleblowersList'));
const WhistleblowersOpen = lazy(() => import('components/smart/whistleblowers/open/WhistleblowersOpen'));
const TodosList = lazy(() => import('components/smart/todos/TodosList'));
const AnnouncementsList = lazy(() => import('components/smart/announcements/AnnouncementsList'));
const Settings = lazy(() => import('components/smart/settings/Settings'));
const Pending = lazy(() => import('components/smart/pending/Pending'));

@withRouter
@inject("ui", "auth", "lang", "profile", "organizations", "announcements") 
@observer
class AppUser extends Component {

    searchByKeyword = () => {

        this.props.ui.openModal(<Dialog type="prompt-text" title={this.props.lang.get.customers.title} subtitle={this.props.lang.get.customers.search.text}  onSubmit={(keyword) => {

            if(this.props.history.location.pathname !== "/customers") {
                this.props.history.push("/customers");
            }

            this.props.ui.setKeyword(keyword);
        }}/>);
    }

    async componentDidMount() {

        const user = await this.props.profile.get({id: "me"});

        if(this.props.auth.isLoggedIn === false) {
            return false;
        }

        if(this.props.profile.isResponseFailed(user)) {            
            let errors = this.props.lang.getErrorMessages(user);
            this.props.ui.openModal(<Dialog type="alert" title={this.props.lang.get.dialog.error} description={errors} animation="error" onClose={() => window.location.reload()}/>);
            return false;
        }

        const organization = await this.props.organizations.getCurrent();

        if(this.props.organizations.isResponseFailed(organization)) {
            let errors = this.props.lang.getErrorMessages(organization);
            this.props.ui.openModal(<Dialog type="alert" title={this.props.lang.get.dialog.error} description={errors} animation="error" onClose={() => window.location.reload()}/>);
            return false;
        }

        // update language if not in sync
        if(this.props.profile.me.language !== this.props.lang.language) {
            this.props.profile.updateLanguage(this.props.lang.language);
        }

        this.props.ui.setTitle(organization.data.name);
        this.props.ui.transition(this.props.location.pathname);

    }

    render() {

        if(this.props.organizations.cnt === 0 || this.props.profile.me === false) {
            return <Loading/>;
        }

        const forcePasswordChange = this.props.profile.me.force_passwordchange === true && <Route path="/" component={ProfilePassword}/>;
        const run = this.props.ui.isContentVisible && this.props.profile.me.force_passwordchange === false && <Run/>;

        let className = this.props.ui.isContentVisible && "on";
    
        const settings = (this.props.profile.me.is_admin === true) && <Route path="/settings" component={Settings}/>;


        // Keyboard shortcuts
        const keyMap = {
            SEARCH: "f",
            ADD: "+",
            PROFILE: "p"
        };

        // Shortcut handlers
        const handlers = {
            SEARCH: (e) => { this.searchByKeyword(); },
            ADD: (e) => { this.props.history.push("/add"); },
            PROFILE: (e) => { this.props.history.push("/profile"); }
        };

        return (

            <div className="AppUser">

                <GlobalHotKeys keyMap={keyMap} handlers={handlers}/>

                <Init/>

                {run}    

                <Menu/>
            
                <Header>
                    <Header.Column icon="menu" onClick={this.props.ui.openMenu}/>
                    <Header.Column title={this.props.ui.title}/>
                    <Header.Column icon="search" onClick={this.searchByKeyword}/>
                    <Link to="/add"><Header.Column icon="add"/></Link>
                    <Link to="/profile"><Header.Column icon="person"/></Link>
                </Header> 
                
                <Content className={className} withHeader="true" withFooter={this.props.ui.isFooterVisible}>

                    <Suspense fallback={<Loading/>}>

                        <Switch>
                            {forcePasswordChange}
                            <Route exact path="/" component={Home}/>
                            <Route path="/add" component={Add}/>
                            <Route path="/tasks" component={Tasks}/>
                            <Route path="/search" component={Search}/>
                            <Route path="/filters" component={Filter}/>
                            <Route path="/profile" component={Profile}/>
                            <Route path="/customers" component={CustomersList}/>
                            <Route path="/removedcustomers" component={RemovedCustomersList}/>
                            <Route path="/queries" component={QueriesList}/>
                            <Route path="/whistleblowers/:id" component={WhistleblowersOpen}/>
                            <Route path="/whistleblowers" component={WhistleblowersList}/>
                            <Route path="/todos" component={TodosList}/>
                            <Route path="/pending" component={Pending}/>
                            <Route path="/announcements/:id" component={AnnouncementsList}/>
                            <Route path="/announcements" component={AnnouncementsList}/>
                            <Route path="/alerts" component={AlertsList}/>
                            {settings}
                        </Switch>

                    </Suspense>
                
                </Content>

            </div>
        ); 
    }

}

export default AppUser;

