import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class RequestStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.requests;
        this.customers_id = false;
        this.sort = "created_at";
    }

    defaultFields = [
        "ask_actualbeneficiaries",
        "ask_actualbeneficiaries_description",
        "ask_actualbeneficiaries_validssn",
        "ask_files",
        "ask_files_description",
        "ask_freetext",
        "ask_freetext_description",
        "ask_owners",
        "ask_owners_description",
        "ask_peps",
        "ask_peps_description",
        "ask_responsiblepersons",
        "ask_responsiblepersons_description",
        "ask_responsiblepersons_ifnecessary",
        "ask_responsiblepersons_ssn",
        "ask_responsiblepersons_validssn",
        "ask_riskquestions",
        "ask_sof",
        "ask_sof_description",
        "autofill_actualbeneficiaries",
        "autofill_owners",
        "autofill_peps",
        "autofill_responsiblepersons",
        "description",
        "language",
        "note",
        "prefill_actualbeneficiaries",
        "prefill_owners",
        "prefill_peps",
        "prefill_responsiblepersons",
        "riskquestions_type"
    ];

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.requests.replace(":id", id);

    }

    async file(id, files_id) {

        let request = this.data.get(id);

        if(request === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // PDF
    async pdf(id) {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            console.log(this.prepareUrl(this.url + "/" + id + "/pdf"));
            return this.prepareUrl(this.url + "/" + id + "/pdf?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    @action.bound async send(id) {

        const res = await this.post(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/send")}
        );

        return res;
    }

    preview(id) {
        
        const url = this.prepareUrl(api.endpoints.preview.requests.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":id", id));

        window.open(url);
    }

    publicUrl(id) {
        return this.prepareUrl(api.endpoints.publicUrls.requests.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":id", id));
    }
    
}
