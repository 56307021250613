import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class CustomerStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.customers;
        this.sort = "name";
    }

    @action.bound async updateFromBis(data) {

        let settings = {
            url: api.endpoints.user.bis.replace(":id", data.id)
        }

        return this.patch(data, settings);
    }

    @action.bound async checkBis(data) {

        let settings = {    
            url: api.endpoints.user.bis.replace(":id", data.id)
        }

        return this.put(data, settings);
    }

    @action.bound async toggleTag(data) {

        let settings = {
            url: api.endpoints.user.tag.replace(":id", data.id) + "/" + data.tags_id
        }

        return this.patch({}, settings);
    }

    @action.bound async changeUser(data) {

        let settings = {
            url: api.endpoints.admin.customers + "/" + data.id
        }

        return this.put(data, settings);
    }

    @action.bound async changeTeam(data) {

        let settings = {
            url: api.endpoints.user.customers + "/" + data.id + "/team"
        }

        return this.put(data, settings);
    }

    @action.bound async changeDomain(data) {

        let settings = {
            url: api.endpoints.user.customers + "/" + data.id + "/domain"
        }

        return this.put(data, settings);
    }

    @action.bound async removePermanently(data) {

        let settings = {
            url: api.endpoints.admin.customers + "/" + data.id
        }

        return this.delete(data, settings);
    }
    
    async suggest(search, country) {
        return this.fetch({}, {url: api.endpoints.common.companysearch + "/" + country + "/?s=" + encodeURIComponent(search)});
    }

    exists(data) {

        const matches = this.list.filter((v) => {
        
            if(v.name.toLowerCase() === data.name.toLowerCase()) {
                return true;
            }

            if(v.type === "business") {
                if(v.businessid) {
                    if(v.businessid === data.businessid) {
                        return true;
                    }
                }
            }

            if(v.type === "person") {
                if(v.ssn) {
                    if(v.ssn === data.ssn) {
                        return true;
                    }
                }
            }

            return false;

        });
        
        return matches.length > 0;

    }

    // Mark task as complete 
    @action.bound async taskDone(data) {

        let settings = {
            url: api.endpoints.user.task.replace(":id", data.id) + "/" + data.tasks_id
        }

        return this.patch({"mode": "add"}, settings);
    }

    // Mark task as pending
    @action.bound async taskPending(data) {

        let settings = {
            url: api.endpoints.user.task.replace(":id", data.id) + "/" + data.tasks_id
        }

        return this.patch({"mode": "remove"}, settings);
    }

    // Toggle on monitoring on/off
    @action.bound async toggleMonitoring(id, onoff, monitor_emails) {

        let settings = {
            url: api.endpoints.user.monitors.replace(":id", id)
        }

        return await this.patch({"is_monitored": onoff, "monitor_emails": monitor_emails}, settings);

    }

    // Toggle on kyc clock on/off
    @action.bound async toggleKYCClock(id, onoff, kycclock_alarm_at) {

        let settings = {
            url: api.endpoints.user.kycclock.replace(":id", id)
        }

        return await this.patch({"is_kycclock": onoff, "kycclock_alarm_at": kycclock_alarm_at}, settings);

    }

    // Download csv
    async csv() {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/export/csv?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // Download pdf
    async pdf() {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.reports + "/pdf/customers?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // Summary
    async summary(id) {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(this.url + "/" + id + "/pdf?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // Get all unique emails
    getUniqueEmails() {

        let emails = [];
    
        let customer = this.data.get(this.root.identifications.customers_id);
        if(!customer) { customer = this.data.get(this.root.esignatures.customers_id);   }
        if(!customer) { customer = this.data.get(this.root.requests.customers_id);      }
        if(!customer) { customer = this.data.get(this.root.letters.customers_id);        }

        if(customer) {
            if(customer.email) {
                emails.push(customer.email);
            }
        }

        this.root.identifications.list.forEach((el) => {
            if(el.is_manual !== true) {
                emails.push(el.email);
            }
        });

        this.root.signatories.list.forEach((el) => {
            emails.push(el.email);
        });

        this.root.requests.list.forEach((el) => {
            emails.push(el.email);
        });

        this.root.letters.list.forEach((el) => {
            emails.push(el.email);
        });

        return emails.filter((x, i, a) => a.indexOf(x) === i);
    }

}
