import DoksApiStore from './DoksApiStore';
import AuthStore from './AuthStore';
import UiStore from './UiStore';
import LangStore from './LangStore';
import OrganizationStore from './OrganizationStore';
import AnnouncementStore from './AnnouncementStore';
import UserStore from './UserStore';
import ProfileStore from './ProfileStore';
import TextStore from './TextStore';
import TeamStore from './TeamStore';
import QueryStore from './QueryStore';
import WhistleblowerStore from './WhistleblowerStore';
import StatementStore from './StatementStore';
import TaskStore from './TaskStore';
import TagStore from './TagStore';
import DomainStore from './DomainStore';
import RiskratingStore from './RiskratingStore';
import RiskscoreStore from './RiskscoreStore';
import RiskquestionStore from './RiskquestionStore';
import RiskanswerStore from './RiskanswerStore';
import RiskassessmentStore from './RiskassessmentStore';
import AttachmentStore from './AttachmentStore';
import DocumentStore from './DocumentStore';
import CustomerStore from './CustomerStore';
import RequestStore from './RequestStore';
import MonitorStore from './MonitorStore';
import SignatureStore from './SignatureStore';
import EsignatureStore from './EsignatureStore';
import SignatoryStore from './SignatoryStore';
import LetterStore from './LetterStore';
import AlertStore from './AlertStore';
import IdentificationStore from './IdentificationStore';
import OwnerStore from './OwnerStore';
import ActualbeneficiaryStore from './ActualbeneficiaryStore';
import PepStore from './PepStore';
import ResponsiblepersonStore from './ResponsiblepersonStore';
import TodoStore from './TodoStore';
import PriceStore from './PriceStore';
import SearchStore from './SearchStore';
import PendingStore from './PendingStore';
import EIDStore from './EIDStore';
import CountryStore from './CountryStore';
import FilterStore from './FilterStore';

// Create stores
export default class Stores {

    constructor() {

        this.ui = new UiStore(this);
        this.lang = new LangStore(this);
        this.auth = new AuthStore(this);
        this.api = new DoksApiStore(this);        
        this.organizations = new OrganizationStore(this);        
        this.announcements = new AnnouncementStore(this);        
        this.users = new UserStore(this);        
        this.profile = new ProfileStore(this);        
        this.texts = new TextStore(this);        
        this.teams = new TeamStore(this);        
        this.tags = new TagStore(this);        
        this.domains = new DomainStore(this);        
        this.tasks = new TaskStore(this);        
        this.riskratings = new RiskratingStore(this);        
        this.riskquestions = new RiskquestionStore(this);        
        this.riskanswers = new RiskanswerStore(this);        
        this.riskassessments = new RiskassessmentStore(this);        
        this.riskscores = new RiskscoreStore(this);        
        this.attachments = new AttachmentStore(this);        
        this.documents = new DocumentStore(this);        
        this.customers = new CustomerStore(this);        
        this.requests = new RequestStore(this);        
        this.monitors = new MonitorStore(this);
        this.alerts = new AlertStore(this);        
        this.signatures = new SignatureStore(this);        
        this.esignatures = new EsignatureStore(this);        
        this.signatories = new SignatoryStore(this);        
        this.letters = new LetterStore(this);        
        this.identifications = new IdentificationStore(this);        
        this.owners = new OwnerStore(this);        
        this.actualbeneficiaries = new ActualbeneficiaryStore(this);        
        this.peps = new PepStore(this);        
        this.responsiblepersons = new ResponsiblepersonStore(this);        
        this.todos = new TodoStore(this);        
        this.prices = new PriceStore(this);        
        this.search = new SearchStore(this);        
        this.queries = new QueryStore(this);        
        this.whistleblowers = new WhistleblowerStore(this);        
        this.statements = new StatementStore(this);        
        this.pending = new PendingStore(this);        
        this.eids = new EIDStore(this);        
        this.countries = new CountryStore(this);        
        this.filters = new FilterStore(this);        
    }
}

