import DoksApiStore from './DoksApiStore';
import { action, runInAction } from 'mobx';
import api from 'api.json';

export default class AlertStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.alerts.all;
        this.customers_id = false;
        this.sort = "description";
    }

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;

        if(id) {
            this.data.forEach((el) => {
                if(el.customers_id === id) {
                    this.data.delete(id);
                }
            });
        } else {
            this.data.clear();
        }

        if(id) {
            this.url = api.endpoints.user.alerts.customer.replace(":id", id);
        } else {
            this.url = api.endpoints.user.alerts.all;
        }
    }

    async dsa(data) {

        if(!data) {
            return false;
        }

        if(!data.metadata) {   
            return false;
        }

        if(!data.metadata.dsaId) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.dsa + "/" + data.metadata.dsaId + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    @action.bound async unsilence(id) {

        const res = await this.delete(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/silence")}
        );

        return res;
    }

    @action.bound async silence(id, description, valid_at) {

        const res = await this.post(
            {description: description, valid_at: valid_at},
            {url: this.prepareUrl(this.url + "/" + id + "/silence")}
        );

        return res;
    }

    @action.bound async refresh() {

        if(!this.customers_id) {      
            return false;
        }

        const res = await this.patch();

        if(this.isResponseOk(res)) {

            if(res.data instanceof Array) {

                runInAction(() => {
                    this.data.clear();
                    res.data.map( (item) => {
                        this.data.set(item.id, item);
                        return true;
                    })
                });

            } 
        }

        return res;
    }

    getUrl(data) {

        let url = "/customers/" + data.customers_id;

        switch(data.type) {

            case "IDENT_MISMATCH":
            case "IDENT_SANCTION":
            url += "/identifications/" + data.target_id;
            break;

            case "SIGN_MISMATCH":
            case "SIGN_SANCTION":
            url += "/signatures/" + data.target_id;
            break;

            case "SIGNATORY_MISMATCH":
            case "SIGNATORY_SANCTION":
            // split target id and add signatories
            url += "/esignatures/" + data.target_id.replace("/", "/signatories/");
            break;

            case "OWNER_SANCTION":
            url += "/owners/" + data.target_id;
            break;

            case "ACTUALBENEFICIARY_SANCTION":
            url += "/actualbeneficiaries/" + data.target_id;
            break;

            case "RESPONSIBLEPERSON_SANCTION":
            url += "/responsiblepersons/" + data.target_id;
            break;

            case "PEP_SANCTION":
            url += "/peps/" + data.target_id;
            break;

            case "SANCTION":
            url += "/update";
            break;

            case "DOC":
            url += "/documents/" + data.target_id;
            break;

            case "RISKASSESSMENT":
            url += "/riskassessments/" + data.target_id;
            break;

            default:
        }

        return url;
    }
    
}
