import DoksApiStore from './DoksApiStore';
import api from 'api.json';
import { observable, action } from 'mobx';

export default class FilterStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.filters;
        this.sort = "name";
    }

    itemGroups = [
        "CREATED",
        "DEPARTMENT",
        "DOMAIN",
        "COMPANYTYPE",
        "INDUSTRY",
        "CITY",
        "COUNTRY",
        "TASKS",
        "RISKSCORE",
        "RISKASSESSMENTS",
        "KYCCLOCK",
        "USER",
        "TEAM",
        "TAGS", 
        "TODOS",
        "ACTUALBENEFICIARIES",
        "RESPONSIBLEPERSONS",
        "PEPS",
        "IDENTIFICATIONS",
        "REQUESTS",
        "LETTERS",
        "ESIGNATURES",
        "DOCUMENTS",
        "ALERTS",
        "FI",
        "SE",
        "NO",
        "DK"
    ];

    itemTypes = [
        "CREATED_AT",
        "DEPARTMENT_IS",
        "DEPARTMENT_IS_NOT",
        "DOMAIN_IS",
        "DOMAIN_IS_NOT",
        "COMPANYTYPE_IS",
        "COMPANYTYPE_IS_NOT",
        "INDUSTRY_IS",
        "INDUSTRY_IS_NOT",
        "CITY_IS",
        "CITY_IS_NOT",
        "COUNTRY_IS",
        "COUNTRY_IS_NOT",
        "COUNTRY_IS_HIGHRISKCOUNTRY",
        "TASKS_COMPLETED",
        "TASKS_PENDING",
        "RISKSCORE_LEVEL_IS",
        "RISKSCORE_LEVEL_IS_NOT",
        "RISKASSESSMENTS_EXISTS",
        "RISKASSESSMENTS_IS_EXPIRED",
        "RISKASSESSMENTS_RISKRATING_IS",
        "RISKASSESSMENTS_RISKRATING_IS_NOT",
        "KYCCLOCK_ISSET",
        "KYCCLOCK_ALARMS",
        "KYCCLOCK_ALARM_AT",
        "USER_IS",
        "USER_IS_NOT",
        "TEAM_IS",
        "TEAM_IS_NOT",
        "TAGS_IS",
        "TAGS_IS_NOT",
        "TODOS_EXISTS",
        "TODOS_IS_PENDING",
        "TODOS_USER_IS",
        "TODOS_RESPONSIBLE_USER_IS",
        "ACTUALBENEFICIARIES_EXISTS",
        "ACTUALBENEFICIARIES_LAST",
        "ACTUALBENEFICIARIES_COUNTRY_IS",
        "ACTUALBENEFICIARIES_COUNTRY_IS_NOT",
        "RESPONSIBLEPERSONS_EXISTS",
        "RESPONSIBLEPERSONS_LAST",
        "RESPONSIBLEPERSONS_COUNTRY_IS",
        "RESPONSIBLEPERSONS_COUNTRY_IS_NOT",
        "PEPS_EXISTS",
        "PEPS_LAST",
        "IDENTIFICATIONS_EXISTS",
        "IDENTIFICATIONS_IS_PENDING",
        "IDENTIFICATIONS_IS_MANUAL",
        "IDENTIFICATIONS_NOT_ACCEPTED",
        "IDENTIFICATIONS_LAST",
        "REQUESTS_EXISTS",
        "REQUESTS_IS_PENDING",
        "REQUESTS_LAST",
        "ESIGNATURES_EXISTS",
        "ESIGNATURES_IS_PENDING",
        "ESIGNATURES_LAST",
        "LETTERS_EXISTS",
        "LETTERS_IS_PENDING",
        "LETTERS_LAST",
        "DOCUMENTS_EXISTS",
        "DOCUMENTS_MISSING",
        "DOCUMENTS_IS_EXPIRED",
        "ALERTS_EXISTS",
        "ALERTS_IS_SILENCED",
        "FI_IS_BANKRUPTCY",
        "FI_IS_LIQUIDATION",
        "FI_IS_RESTRUCTURING",
        "FI_IS_INTERRUPTION",
        "FI_IS_DEFUNCT",
        "FI_IS_ENDED",
        "FI_IS_PREPAYMENTREGISTER",
        "SE_STATUS_IS",
        "SE_STATUS_IS_NOT",
        "NO_IS_BANKRUPTCY",
        "NO_IS_LIQUIDATION",
        "NO_STATUS_IS",
        "NO_STATUS_IS_NOT",
        "DK_IS_BANKRUPTCY",
        "DK_IS_LIQUIDATION",
        "DK_IS_RESTRUCTURING",
        "DK_IS_INTERRUPTION",
        "DK_IS_DEFUNCT",
        "DK_IS_ENDED",
        "DK_STATUS_IS",
        "DK_STATUS_IS_NOT"
    ];

     TIMEFRAMES = [

         {"group": "common", "name": "today", "value": {"from": "today 00:00:00", "to": "today 23:59:59"}},
         {"group": "common", "name": "yesterday", "value": {"from": "yesterday 00:00:00", "to": "yesterday 23:59:59"}},
         {"group": "common", "name": "tomorrow", "value": {"from": "tomorrow 00:00:00", "to": "tomorrow 23:59:59"}},

         {"group": "ago", "name": "1 week", "value": {"from": "0", "to": "today 00:00:00 - 1 week"}},
         {"group": "ago", "name": "2 week", "value": {"from": "0", "to": "today 00:00:00 - 2 week"}},
         {"group": "ago", "name": "1 month", "value": {"from": "0", "to": "today 00:00:00 - 1 month"}},
         {"group": "ago", "name": "3 month", "value": {"from": "0", "to": "today 00:00:00 - 3 month"}},
         {"group": "ago", "name": "6 month", "value": {"from": "", "to": "today 00:00:00 - 6 month"}},
         {"group": "ago", "name": "1 year", "value": {"from": "0", "to": "today 00:00:00 - 1 year"}},
         {"group": "ago", "name": "2 year", "value": {"from": "0", "to": "today 00:00:00 - 2 year"}},
         {"group": "ago", "name": "other", "value": {"from": "0", "to": "today 00:00:00 - {{other}} day"}},

         {"group": "since", "name": "week", "value": {"from": "this week 00:00:00", "to": "today 23:59:59"}},
         {"group": "since", "name": "month", "value": {"from": "first day of this month 00:00:00", "to": "today 23:59:59"}},
         {"group": "since", "name": "year", "value": {"from": "first day of january this year 00:00:00", "to": "today 23:59:59"}},
         {"group": "since", "name": "1 week", "value": {"from": "today 00:00:00 - 1 week", "to": "today 23:59:59"}},
         {"group": "since", "name": "2 week", "value": {"from": "today 00:00:00 - 2 week", "to": "today 23:59:59"}},
         {"group": "since", "name": "1 month", "value": {"from": "today 00:00:00 - 1 month", "to": "today 23:59:59"}},
         {"group": "since", "name": "3 month", "value": {"from": "today 00:00:00 - 3 month", "to": "today 23:59:59"}},
         {"group": "since", "name": "6 month", "value": {"from": "today 00:00:00 - 6 month", "to": "today 23:59:59"}},
         {"group": "since", "name": "1 year", "value": {"from": "today 00:00:00 - 1 year", "to": "today 23:59:59"}},
         {"group": "since", "name": "2 year", "value": {"from": "today 00:00:00 - 2 year", "to": "today 23:59:59"}},
         {"group": "since", "name": "other", "value": {"from": "today 00:00:00 - {{other}} day", "to": "today 23:59:59"}},

         {"group": "within", "name": "week", "value": {"from": "today 00:00:00", "to": "next week - 1 day 23:59:59"}},
         {"group": "within", "name": "month", "value": {"from": "today 00:00:00", "to": "last day of this month 23:59:59"}},
         {"group": "within", "name": "year", "value": {"from": "today 00:00:00", "to": "last day of december this year 23:59:59"}},
         {"group": "within", "name": "1 week", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 1 week"}},
         {"group": "within", "name": "2 week", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 2 week"}},
         {"group": "within", "name": "1 month", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 1 month"}},
         {"group": "within", "name": "3 month", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 3 month"}},
         {"group": "within", "name": "6 month", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 6 month"}},
         {"group": "within", "name": "1 year", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 1 year"}},
         {"group": "within", "name": "2 year", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + 2 year"}},
         {"group": "within", "name": "other", "value": {"from": "today 00:00:00", "to": "today 23:59:59 + {{other}} day"}},

         {"group": "after", "name": "1 week", "value": {"from": "today 00:00:00 + 1 week", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "2 week", "value": {"from": "today 00:00:00 + 2 week", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "1 month", "value": {"from": "today 00:00:00 + 1 month", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "3 month", "value": {"from": "today 00:00:00 + 3 month", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "6 month", "value": {"from": "today 00:00:00 + 6 month", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "1 year", "value": {"from": "today 00:00:00 + 1 year", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "2 year", "value": {"from": "today 00:00:00 + 2 year", "to": "today 23:59:59 + 1000 year"}},
         {"group": "after", "name": "other", "value": {"from": "today 00:00:00 + {{other}} day", "to": "today 23:59:59 + 1000 year"}},
     ];

    @observable items = [];
    @observable filtersId = null;
    @observable filterData = null;

    @action.bound setFilterData = (data) => {
        this.filterData = data;
    }

    @action.bound resetFilterData = (data) => {
        this.filterData = null;
    }

    @action.bound async getFilterData() {

        let url = api.endpoints.user.filters + "/filter/json";
        return this.fetch({"filters": this.items}, {"method": "PATCH", url: url});
    }

    async downloadAsCsvUrl() {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.filters + "/filter/csv?jwt=" + encodeURIComponent(res.data.jwt) + "&filters=" + encodeURIComponent(JSON.stringify(this.items)));
        }

        return false;
    }

    @action.bound addItem = (item) => {

        if(!this.itemTypes.includes(item.type)) {
            return false;
        }

        this.setFiltersId(null);
        item.id = this.items.length+1;       // ??
        this.items = this.items.concat(item);
    }

    @action.bound setSavedItems = (items) => {
        this.resetItems();
        items.forEach((item) => {
            this.addItem(item);
        });
    }

    @action.bound removeItem = (id) => {
        this.items = this.items.filter((el) => {
            return el.id !== id;
        });
    }

    @action.bound resetItems = () => {
        this.setFiltersId(null);
        this.items = [];
    }

    @action.bound setFiltersId = (id) => {
        this.filtersId = id;
    }

    // Alias for POST
    @action.bound add(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.filters;
        return this.post(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        settings = {};
        settings.url = api.endpoints.admin.filters + "/" + data.id;
        return this.delete(data, settings);
    }
}

