import DoksApiStore from './DoksApiStore';
import { observable, action, computed, runInAction } from 'mobx';
import api from 'api.json';

export default class UserStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.users;
    }

    @observable avatarUploadProgress = 0;
    @observable avatarUploadFilename = "";
    @observable avatarUploadTime = 0;

    @computed get me() {

        const data = this.data.get(this.root.auth.id);

        if(data === undefined) {
            return false;
        }

        return data;
    }

    @action.bound updateLanguage(language) {

        if(this.me) {
            const data = Object.assign({}, this.me, {language: language});
            return this.update(data);
        }
    }

    @action.bound getAvatarUrl(id, cache) {
        return this.prepareUrl(api.endpoints.public.avatar) + "/" + id + "?" + cache;
    }

    @computed get avatar() {
        return this.getAvatarUrl(this.root.auth.id, this.avatarUploadTime);
    }

    @action.bound async changePassword(password) { 

        return this.patch({
            "password": password
        });
    }

    async uploadAvatar(file, cancelToken) {

        if(!file) {
            return false;
        }

        let data = new FormData();
        data.append("file", file);

        runInAction(() => {
            this.avatarUploadFilename = file.name;
            this.avatarUploadProgress = 0;
        });

        const res = await this.post(data, {
            url: api.endpoints.user.avatar, 
            cancelToken: cancelToken,
            progress: (e) => {

                runInAction(() => {
                    this.avatarUploadProgress = parseInt(e.loaded/e.total*100, 10);
                });
            }
        });

        runInAction(() => {
            this.avatarUploadProgress = 100;
            this.avatarUploadTime = Math.random().toString(36);
        });

        return res;
    }

    async zendesk() {

        const res = await this.get({}, {"url": api.endpoints.user.zendesk});

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return res.data.url;
        }

        return false;
    }
}
