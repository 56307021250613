import DoksApiStore from './DoksApiStore';
import { observable, action, runInAction } from 'mobx';
import api from 'api.json';

export default class DocumentStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.documents;
        this.customers_id = false;
        this.sort = "created_at";
    }

    @observable uploadProgress = 0;
    @observable uploadFilename = "";

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.documents.replace(":id", id);
    }

    @observable uploadProgress = 0;
    @observable uploadFilename = "";

    @action.bound async uploadByFilesId(files_id) {
        return this.post({ "files_id": files_id }, { "url": this.url });
    }

    @action.bound async upload(file, cancelToken) {

        if(!file) {
            return false;
        }

        let data = new FormData();
        data.append("file", file);
        data.append("name", file.name);

        runInAction(() => {
            this.uploadFilename = file.name;
            this.uploadProgress = 0;
        });

        const res = await this.post(data, {
            url: this.url,
            cancelToken: cancelToken,
            progress: (e) => {

                runInAction(() => {
                    this.uploadProgress = parseInt(e.loaded/e.total*100, 10);
                });
            }
        });

        runInAction(() => {
            this.uploadProgress = 100;
        });

        return res;
    }

    async files_id2url(files_id) {

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    // we can convert this function to use files_id2url!
    async file(id) {

        let document = this.data.get(id);

        if(document === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + document.files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    @action.bound async traderegister() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.traderegister.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async associationregister() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.associationregister.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async associationregisterrules() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.associationregisterrules.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async foundationregister() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.foundationregister.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async foundationregisterrules() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.foundationregisterrules.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async traderegistertranslation_en() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.traderegistertranslation_en.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async traderegistertranslation_se() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.traderegistertranslation_se.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async actualbeneficiaries() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.actualbeneficiaries.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async actualbeneficiaries_dk() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.actualbeneficiaries_dk.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async actualbeneficiaries_se() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.actualbeneficiaries_se.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async actualbeneficiaries_ee() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.actualbeneficiaries_ee.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async responsiblepersons() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.responsiblepersons.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async responsiblepersons_dk() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.responsiblepersons_dk.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async owners_dk() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.owners_dk.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async responsiblepersons_se() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.responsiblepersons_se.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async responsiblepersons_ee() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.responsiblepersons_ee.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async responsiblepersons_no() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.responsiblepersons_no.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async companybylaws() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.companybylaws.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async financialstatement(documentNumber) {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.financialstatement.replace(":id", this.customers_id);

        if(documentNumber) {
            return this.fetch({"document": documentNumber}, {method: "POST", url: url});
        }

        return this.fetch({}, {method: "GET", url: url});

    }

    @action.bound async remarks() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.remarks.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    @action.bound async rating() {

        if(this.customers_id === false) {
            return false;
        }

        let url = api.endpoints.user.buy.rating.replace(":id", this.customers_id);

        return this.fetch({}, {method: "POST", url: url});
    }

    // Function to determine if we can get certain type of document
    canGet = (type, customer) => {

        if(typeof customer === "string") {
            customer = this.root.customers.data.get(customer);
        }       

        if(!this.root.organizations.my) {
            return false;
        }

        if(!customer) {
            return false;
        }

        // Nothing for individuals, at least not yet
        if(customer.type === "person") {
            return false;
        }

        // Different document types
        switch(type) {

            case "traderegister":

            if(customer.country !== "FI") {
                return false;
            }

            // Skip aatteellinen yhdistys and säätiö
            if(customer.companytype_code && ["6","18"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_traderegister;


            case "traderegistertranslation_en":

            if(customer.country !== "FI") {
                return false;
            }

            // Skip aatteellinen yhdistys and säätiö
            if(customer.companytype_code && ["6","18"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_traderegistertranslation_en;


            case "traderegistertranslation_se":

            if(customer.country !== "FI") {
                return false;
            }

            // Skip aatteellinen yhdistys and säätiö
            if(customer.companytype_code && ["6","18"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_traderegistertranslation_se;


            case "associationregister":

            if(customer.country !== "FI") {
                return false;
            }

            // Only for aatteellinen yhdistys
            if(customer.companytype_code && !["6"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_associationregister;

            case "associationregisterrules":

            if(customer.country !== "FI") {
                return false;
            }

            // Only for aatteellinen yhdistys
            if(customer.companytype_code && !["6"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_associationregisterrules;


            case "foundationregister":

            if(customer.country !== "FI") {
                return false;
            }

            // Only for säätio
            if(customer.companytype_code && !["18"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_foundationregister;


            case "foundationregisterrules":

            if(customer.country !== "FI") {
                return false;
            }

            // Only for säätio
            if(customer.companytype_code && !["18"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_foundationregisterrules;


            case "actualbeneficiaries":

            if(customer.country !== "FI") {
                return false;
            }

            // Only for osakeyhtiö and julkinen osakeyhtiö
            if(customer.companytype_code && !["16", "17"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_actualbeneficiaries;


            case "actualbeneficiaries_dk":

            if(customer.country !== "DK") {
                return false;
            }

            return this.root.organizations.my.use_documents_actualbeneficiaries_dk;

            case "actualbeneficiaries_ee":

            if(customer.country !== "EE") {
                return false;
            }

            // only for osaühing, aktsiaselts and piiratud
            if(!["OÜ", "AS", "UÜ"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_actualbeneficiaries_ee;

            case "actualbeneficiaries_se":

            if(customer.country !== "SE") {
                return false;
            }

            // Only for aktiebolag and kommanditbolag
            if(!["ab", "kb", "fab"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_actualbeneficiaries_se;

            case "responsiblepersons":

            if(customer.country !== "FI") {
                return false;
            }

            // Skip aatteellinen yhdistys and säätiö and yksityinen elinkeinoharjoittaja
            if(customer.companytype_code && ["6","18","26"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_responsiblepersons;


            case "responsiblepersons_dk":

            if(customer.country !== "DK") {
                return false;
            }

            return this.root.organizations.my.use_documents_responsiblepersons_dk;


            case "owners_dk":

            if(customer.country !== "DK") {
                return false;
            }

            // Only for Aktieselskab
            if(!["60"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_owners_dk;


            case "responsiblepersons_se":

            if(customer.country !== "SE") {
                return false;
            }

            // Only for aktiebolag and kommanditbolag
            if(!["ab", "kb", "fab"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_responsiblepersons_se;


            case "responsiblepersons_no":

            if(customer.country !== "NO") {
                return false;
            }

            // Only for Private aksjeselskaper mv. and Banker
            if(!["2100", "3200"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_responsiblepersons_no;

            case "responsiblepersons_ee":

            if(customer.country !== "EE") {
                return false;
            }

            // only for osaühing, aktsiaselts and piiratud
            if(!["OÜ", "AS", "UÜ"].includes(customer.companytype_code)) {
                return false;
            }

            return this.root.organizations.my.use_documents_responsiblepersons_ee;


            case "companybylaws":

            if(customer.country !== "FI") {
                return false;
            }

            return this.root.organizations.my.use_documents_companybylaws;


            case "financialstatement":

            if(customer.country !== "FI") {
                return false;
            }

            return this.root.organizations.my.use_documents_financialstatements;


            case "rating":

            if(customer.country !== "FI") {
                return false;
            }

            return this.root.organizations.my.use_documents_rating;


            case "remarks":

            if(customer.country !== "FI") {
                return false;
            }

            return this.root.organizations.my.use_documents_remarks;


            case "paymenthistory":

            if(customer.country !== "FI") {
                return false;
            }

            return this.root.organizations.my.use_documents_paymenthistory;


            default:
            return false;
        }
    }
}
