import { Component } from 'react';
import { observable, action, computed, runInAction } from 'mobx';
import naturalSort from 'javascript-natural-sort';
import api from 'api.json';
import axios from 'axios';

export default class Apistore extends Component {

    constructor(root) {
        
        super();

        this.root = root;
        this.hosts = [];
        this.url = false;
        this.resumeTime = 200000;

        api.hosts.forEach( (host) => {
            this.hosts.push({"host": host, "resumeTime": 0});
        });

    }

    // All data is saved here
    @observable data = new Map();

    // Loading status for this store
    @observable loading = 0;

    // Indicator to see if something has been already loaded or not
    @observable isSomethingLoaded = false;

    // Set loading status
    @action.bound setLoading(cnt) {

        this.loading += cnt;

        if(this.loading === 0) {
            if(this.isSomethingLoaded === false) {
                this.isSomethingLoaded = true;
            }
        }
    }

    // Url randomizer with working hosts
    prepareUrl = (url) => {

        // Get host randomly 
        const newHost = this.getWorkingHostRandomly();

        // Get all hosts and replace if found
        api.hosts.forEach( (oldHost) => {
            url = url.replace(oldHost, newHost);
        });

        return url;

    }

    // Shuffle host
    @action shuffleHosts = () => {

        function shuffle (array) {
          var i = 0
            , j = 0
            , temp = null

          for (i = array.length - 1; i > 0; i -= 1) {
            j = Math.floor(Math.random() * (i + 1))
            temp = array[i]
            array[i] = array[j]
            array[j] = temp
          }
        }

        shuffle(this.hosts);
    }

    // Get single working host randomly
    getWorkingHostRandomly = () => {

        if(this.hosts.length === 0) {
            return false;
        }

        this.shuffleHosts();

        const time = new Date().getTime();

        for(let i = 0; i < this.hosts.length; i++) {
            if(this.hosts[i].resumeTime < time) {
                return this.hosts[i].host;
            }
        }

        // All hosts are broken! We can still try one..
        return this.hosts[0].host;

    }

    // Loading status
    @computed get isLoading() {
        return this.loading > 0;
    }

    // Number of data elements
    @computed get cnt() {
        return Array.from(this.data).length;
    }

    // List data
    @computed get list() {

        if(this.sort) {

            naturalSort.insensitive = true;

            let data = Array.from(this.data.values()).sort( (a, b) => {

                if(a[this.sort] === b[this.sort]) {
                    if(this.sort2) {
                        return naturalSort(a[this.sort2], b[this.sort2]);
                    }
                }

                return naturalSort(a[this.sort], b[this.sort]);
            });

            if(this.desc === true) {
                return data.reverse();
            }

            return data;

        }

        return Array.from(this.data.values());

    }

    // Error when all retries and other means has been tried
    error = () => {
//        alert("Unexpected error has occured or no network connection available.");
//        window.location.reload(true);
        return false;
    }

    // Create cancel token
    cancelToken = () => {

        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        return source;

    }

    // Response interceptor 
    intercept = (response) => {
        return response;
    }

    // API call
    async fetch(data, settings) {

        if(data === undefined) {
            data = {};
        }

        if(settings === undefined) {
            settings = {};
        }

        // Fill url if not set earlier
        if(settings.url === undefined) {

            settings.url = this.url;

            if(data.id) {
                settings.url += "/" + data.id;
            }
        }

        // Fill method
        if(settings.method === undefined) {
            settings.method = "GET";
        }

        // Fill timeout
        if(settings.timeout === undefined) {
            settings.timeout = 5000;
        }

        // Prepare url
        settings.url = this.prepareUrl(settings.url);

        runInAction(() => {
            // Set global loading status ON
            this.root.ui.setLoading(1);

            // Set own loading status ON
            this.setLoading(1);
        });

        try {

            // See if we use auth store for authentication headers
            let headers = {'Pragma': 'no-cache'};
            if(this.root.auth) {
                headers = {
                    'Pragma': 'no-cache',
                    'X-Authorization': 'Bearer ' +  this.root.auth.jwt,
                    'Authorization': 'Bearer ' +  this.root.auth.jwt
                }
            }

            let response = await axios({
                url: settings.url,
                method: settings.method,
                data: data,
                transformResponse: [(response) => (JSON.parse(response))], 
                cancelToken: settings.cancelToken,
                headers: headers,
                onUploadProgress: settings.progress
            });

            runInAction(() => {
                // Set global loading status OFF
                this.root.ui.setLoading(-1);

                // Set own loading status OFF
                this.setLoading(-1);
            });

            if(response.data === {}) {
                return this.error();
            }

            return this.intercept(response.data);

        } catch (err) {

            runInAction(() => {

                // Set global loading status OFF
                this.root.ui.setLoading(-1);

                // Set own loading status OFF
                this.setLoading(-1);
            });

            if(axios.isCancel(err)) {
                return err;
            }

            // We have response, but its out of range 2xx
            if(err.response) {

                // If error is 403, try to renew token
                if(err.response.status === 403 && settings.renew !== false && this.root.auth) {

                    const res = await this.root.auth.renew();

                    if(this.root.auth.isResponseOk(res)) {
                        settings.renew = false;
                        return this.fetch(data, settings);
                    }

                }

                // Return error data
                return this.intercept(err.response.data);

            }

            // We have some other kind of error: maybe JSON parse error, empty response or something else

            // Try to retry
            if(settings.retry !== false) {

                let canRetry = false;
                const time = new Date().getTime();

                // NOTE: if host is not found at all, nothing can be set to freeze. We could detect this before next for-loop

                for(let i = 0; i < this.hosts.length; i++) {

                    if(settings.url.indexOf(this.hosts[i].host) === 0) {
                        this.hosts[i].resumeTime = time + this.resumeTime;
                    }

                    if(this.hosts[i].resumeTime < time) {
                        canRetry = true;
                    }
                }

                if(canRetry === true) {
                    return this.fetch(data, settings);
                }
            }

            return this.error();

        }

    }

    // GET
    //@action.bound async get(data, settings) {
    async get(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "GET";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data instanceof Array) {

                    runInAction(() => {
                        res.data.map((item) => {
                            return this.data.set(item.id, item);
                        });
                    });

            } else {

                if(res.data.id) {

                    runInAction(() => {
                        this.data.set(res.data.id, res.data);
                    });
                }

            }
        }

        return res;
    }
    
    // PUT
    @action.bound async put(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "PUT";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                runInAction(() => {
                    this.data.set(res.data.id, res.data);
                });
            }
        }

        return res;
    }

    // POST
    @action.bound async post(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "POST";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                runInAction(() => {
                    this.data.set(res.data.id, res.data);
                });
            }
        }

        return res;
    }

    // DELETE
    @action.bound async delete(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "DELETE";
        
        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                runInAction(() => {
                    this.data.delete(res.data.id);
                });
            }
        }

        return res;
    }

    // PATCH
    @action.bound async patch(data, settings) {

        if(settings === undefined) {
            settings = {};
        }

        settings.method = "PATCH";

        // API call
        const res = await this.fetch(data, settings);

        if(axios.isCancel(res)) {
            return res.message;
        }

        if(res && res.status === true) {

            if(res.data.id) {
                runInAction(() => {
                    this.data.set(res.data.id, res.data);
                });
            }
        }

        return res;
    }

    // Alias for POST
    @action.bound add(data, settings) {
        return this.post(data, settings);
    }

    // Alias for PUT
    @action.bound update(data, settings) {
        return this.put(data, settings);
    }

    // Alias for DELETE
    @action.bound remove(data, settings) {
        return this.delete(data, settings);
    }

    // API answer is successfull
    isResponseOk = (res) => {

        if(res === false || res === null) {
            return false;
        }

        if(typeof(res) !== 'object') {
            return false;
        }

        if(!("status" in res)) {
            return false;
        }

        if(res.status === true) {
            return true;
        }

        return false;

    }

    // API answer is failed
    isResponseFailed = (res) => {
        return !this.isResponseOk(res);
    }
}
