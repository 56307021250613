import DoksApiStore from './DoksApiStore';
import { action } from 'mobx';
import api from 'api.json';

export default class IdentificationStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.identifications;
        this.customers_id = false;
        this.sort = "created_at";
    }

    @action.bound setCustomersId(id) {

        if(id === this.customers_id) {
            return true;
        }

        this.customers_id = id;
        this.data.clear();
        this.url = api.endpoints.user.identifications.replace(":id", id);

    }

    async file(id, files_id) {

        let identification = this.data.get(id);

        if(identification === false) {
            return false;
        }

        const res = await this.root.auth.temporary();

        if(this.isResponseFailed(res)) {
            return false;
        }

        if(this.isResponseOk(res)) {
            return this.prepareUrl(api.endpoints.user.files + "/" + files_id + "?jwt=" + encodeURIComponent(res.data.jwt));
        }

        return false;
    }

    @action.bound async send(id) {

        const res = await this.post(
            {},
            {url: this.prepareUrl(this.url + "/" + id + "/send")}
        );

        return res;
    }

    preview(id) {
        
        const url = this.prepareUrl(api.endpoints.preview.identifications.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":id", id));

        window.open(url);
    }

    publicUrl(id) {
        return this.prepareUrl(api.endpoints.publicUrls.identifications.replace("{{host}}", this.root.organizations.my.host).replace(":organizations_id", this.root.organizations.id).replace(":customers_id", this.customers_id).replace(":id", id));
    }

}
