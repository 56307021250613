import React, { Component } from 'react';
import Icon from 'components/dumb/Icon/Icon';
import Flex from 'components/dumb/Flex/Flex';
import { observer } from 'mobx-react';
import { observable, action} from 'mobx';
import './Form.scss';

class Submit extends Component {

    render() {
        return <button className="FormSubmit" type="submit">{this.props.children}</button>;
    }

}

@observer
class Input extends Component {

    inputRef = React.createRef();

    @observable changed = false;
    @observable value = "";

    @action change = (e) => {
        this.changed = true;
        this.value = e.target.value;
        this.props.onChange && this.props.onChange(this.value, e.target.name);
    }

    keydown = (e) => {
        this.props.onKeyDown && this.props.onKeyDown(e.key, e.target.value);
    }

    focus = (e) => {
        this.props.onFocus && this.props.onFocus(e.target.value);
    }

    blur = (e) => {
        this.props.onBlur && this.props.onBlur(e.target.value);
    }

    help = (e) => {
        e.stopPropagation();

        if(typeof this.props.help === "function") {
            this.props.help();
        } else {
            alert(this.props.help);
        }
    }

    @action componentDidMount() {

        this.value = (this.props.value || this.props.value === 0) ? this.props.value : "";
        this.props.onChange && this.props.onChange(this.value);
        
        if(this.props.autoFocus) {
            if(this.inputRef) {
                if(this.inputRef.current) {
                    setTimeout(() => {
                        this.inputRef.current.focus();
                    }, 0);
                }
            }
        }

    }

    onClickIcon = () => {

        if(this.props.onClickIcon) {
            this.props.onClickIcon(this.inputRef.current);
        }    

    }

    @action componentDidUpdate() {

        if(this.changed === false) {
            this.value = (this.props.value || this.props.value === 0) ? this.props.value : "";
        }
    }

    @action toggle = () => {

        this.changed = true;

        if(this.value === this.props.on) {
            this.value = this.props.off;
        } else {
            this.value = this.props.on;
        }

        this.props.onToggle && this.props.onToggle(this.value);
        this.props.onChange && this.props.onChange(this.value);

    }

    render() {

        let {props} = this;

        let classnames = "FormInput type-"+props.type;

        if(props.icon) {
            classnames += " with-icon";
        }

        if(props.invert) {
            classnames += " invert";
        }

        let label;  
        let input;
//        let help = props.help && <span>{props.help}</span>;
        let help = props.help && <Icon className="FormHelp" name="help_outline" onClick={this.help}/>;

        let icon;
        let value;

        switch(props.type) {

            case "hidden":
            input = <input type="hidden" name={props.name} id={props.id} value={this.value} onChange={this.change}/>;
            break;

            case "number":
            input = <input ref={this.inputRef} className={classnames} required={props.required} readOnly={props.readOnly} autoFocus={props.autoFocus} placeholder={props.placeholder} type="number" min={props.min} max={props.max} step={props.step} name={props.name} id={props.id} value={this.value} onChange={this.change} onKeyDown={this.keydown} onFocus={this.focus} onBlur={this.blur} autoComplete={props.autoComplete}/>;
            break;

            case "text":
            case "email":
            input = <input ref={this.inputRef} className={classnames} required={props.required} readOnly={props.readOnly} autoFocus={props.autoFocus} placeholder={props.placeholder} type={props.type} name={props.name} id={props.id} maxLength={props.maxLength} value={this.value} onChange={this.change} onKeyDown={this.keydown} onFocus={this.focus} onBlur={this.blur} autoComplete={props.autoComplete}/>;
            break;

            case "password":
            input = <input ref={this.inputRef} className={classnames} required={props.required} readOnly={props.readOnly} autoFocus={props.autoFocus} placeholder={props.placeholder} type="password" name={props.name} id={props.id} value={this.value} onChange={this.change} onKeyDown={this.keydown} onFocus={this.focus} onBlur={this.blur} autoComplete={props.autoComplete}/>;
            break;

            case "textarea":
            input = <textarea ref={this.inputRef} className={classnames} required={props.required} readOnly={props.readOnly} autoFocus={props.autoFocus} type="password" name={props.name} id={props.id} value={this.value} placeholder={props.placeholder} onChange={this.change} autoComplete={props.autoComplete}/>;
            break;

            case "checkbox":

            value = props.off;
            if(this.value === props.on) {
                value = props.on;
            }

            icon = <Icon className="checkbox" name="check_box_outline_blank"/>;
            if(value === props.on) {
                icon = <Icon className="checkbox checked" name="check_box"/>;
            }

            input = (<div className={classnames} onClick={this.toggle}>

                        <Flex noWrap align="center">
                            <Flex.Column size="min" className="IconContainer">
                                {icon}
                            </Flex.Column>
                            <Flex.Column size="max">
                                <label className="FormLabel">{props.label} {help}</label>
                            </Flex.Column>
                        </Flex>

                        <div className="input">
                            <input type="text" name={props.name} defaultValue={props.required ? (this.value === props.on ? props.on : "") : this.value} id={props.id} required={props.required}/>
                        </div>

                    </div>);
            break;

            case "toggle":

            value = props.off;
            if(this.value === props.on) {
                value = props.on;
            }

            icon = <Icon className="toggle" name="toggle_off"/>;
            if(value === props.on) {
                icon = <Icon className="toggle toggled" name="toggle_on"/>;
            }

            let label = props.label;
            if(props.label_on && this.value === props.on) {
                label = props.label_on;
            }
            if(props.label_off && this.value === props.off) {
                label = props.label_off;
            }

            let topLabel = props.topLabel && props.label_on && props.label_off && <label className="FormLabel">{props.topLabel} {help}</label>
            if(topLabel) {
                help = null;
            }

            input = (<div className={classnames} onClick={this.toggle}>

                        {topLabel}

                        <Flex noWrap align="center">
                            <Flex.Column size="min" className="IconContainer">
                                {icon}
                            </Flex.Column>
                            <Flex.Column size="max">
                                <label className="FormLabel">{label} {help}</label>
                            </Flex.Column>
                        </Flex>

                        <div className="input">
                            <input type="text" name={props.name} defaultValue={props.required ? (this.value === props.on ? props.on : "") : this.value} id={props.id} required={props.required}/>
                        </div>

                    </div>);
            break;

            case "select":

            let options = [];

            if(props.choose) {
                options.push(<option key="choose" value="">{props.choose}</option>);
            }

            props.data.map( (item, k) => {

                let value = item[this.props.valueKey ? this.props.valueKey : "id"];
                let name = item[this.props.nameKey ? this.props.nameKey : "name"];

                options.push(<option key={item.id + "_" + k} value={value}>{name}</option>);
                return true;
            });

            input = <select ref={this.inputRef} className={classnames} required={props.required} readOnly={props.readOnly} autoFocus={props.autoFocus} name={props.name} id={props.id} value={this.value} placeholder={props.placeholder} onChange={this.change}>{options}</select>;
            break;

            default:
        }

        if(props.type !== "checkbox" && props.type !== "toggle") {

            if(props.icon) {

                let iconClassName = "FormInputWrapperIcon";
                if(props.onClickIcon) {
                    iconClassName += " with-click";
                }

                input = <div className="FormInputWrapper"><Icon onClick={this.onClickIcon} className={iconClassName} name={props.icon}/>{input}</div>;
            }

            label = props.label && <label className="FormLabel">{props.label} {help}</label>;
        }

        if(props.type === "hidden") {
            return input;
        }
    
        let ffclassname = "FormFieldset type-" + props.type;
        if(props.noMargin === true) {
            ffclassname += " noMargin";
        }

        return <fieldset className={ffclassname}>{label}{input}</fieldset>

    }

}

class Form extends Component {

    static Input = Input;
    static Submit = Submit;

    change = (e) => {

        this.props.onChange && this.props.onChange(e.target, e.target.value);
    }

    submit = (e) => {
        e.preventDefault();
        this.props.onSubmit && this.props.onSubmit(this.values(e));
    }

    values = (e) => {

        let elements = e.target.elements;
        let data = {};

        for (var i = 0; i < elements.length; i++) {
            
            let elementName = elements[i].name;

            if(elementName) {

                if(elementName.endsWith("[]")) {

                    elementName = elementName.replace("[]", "");
                
                    if(!data[elementName]) {
                        data[elementName] = [];
                    }

                    data[elementName].push(elements[i].value);

                } else {
                    data[elementName] = elements[i].value;
                }
            }
        }

        return data;
    }

    keydown = (e) => {

        if(e.key === "Enter" && e.target.type !== 'textarea' && this.props.submitOnEnter !== true) {
            e.preventDefault();
        }

        if(e.key === "Enter" && this.props.onKeyDownEnter) {
            this.props.onKeyDownEnter(e.target, e.target.value);
        }

        this.props.onKeyDown && this.props.onKeyDown(e.target, e.key, e.target.value);
    }

    render() {
        return <form id={this.props.id} onChange={this.change} onSubmit={this.submit} onKeyDown={this.keydown} autoComplete={this.props.autoComplete}>{this.props.children}</form>;
    }
}

export default Form;



