import React, { Component } from 'react';
import Card from 'components/dumb/Card/Card';
import AnimatedIcon from 'components/dumb/AnimatedIcon/AnimatedIcon';
import CircularProgress from 'components/dumb/CircularProgress/CircularProgress';
import Modal from 'components/dumb/Modal/Modal';
import Button from 'components/dumb/Button/Button';
import Form from 'components/dumb/Form/Form';
import { inject, observer } from 'mobx-react';
import './Dialog.scss';

import { portalTo } from 'components/dumb/Portal/Portal';

@portalTo("App")
@inject("ui", "lang")
@observer
class Dialog extends Component {

    close = () => {

        this.props.ui.closeModal();
        setTimeout(() => {
            this.props.onClose && this.props.onClose();
        }, 0);
    }

    submit = (data) => {

        if(this.props.mandatory) {
            if(data.prompt === "") {
                return false;
            }
        }

        this.close();
        this.props.onSubmit && this.props.onSubmit(data.prompt);        
    }

    confirm = () => {

        setTimeout(() => {
            this.props.onConfirm && this.props.onConfirm();        
            this.close();
        }, 0);
    }

    cancel = () => {
    
        this.close();
        setTimeout(() => {
            this.props.onCancel && this.props.onCancel();
        }, 0);
    }

    componentDidMount() {

        if(this.props.autoDoneTimeout > 0) {
            setTimeout(() => {
                this.close();
            }, this.props.autoDoneTimeout);
        }
    }

    render() {

        let { type } = this.props;

        let close = this.props.autoDoneTimeout === undefined && <Button onClick={this.close} color="grey" icon="check" text={ this.props.close || this.props.lang.get.dialog.close }/>
        let submit = <Form.Submit><Button color="grey" icon="check" text={this.props.submit || this.props.lang.get.dialog.submit }/></Form.Submit>
        let confirm = <Button onClick={this.confirm} icon={this.props.confirmIcon || "check"} color="grey" text={ this.props.confirm || this.props.lang.get.dialog.confirm }/>
        let cancel = <Button onClick={this.cancel} icon={this.props.cancelIcon || "cancel"} color="grey" text={ this.props.cancel || this.props.lang.get.dialog.cancel }/>
        let animation = this.props.animation && <AnimatedIcon key="animation" type={this.props.animation}/>;
        let progress = this.props.progress && <CircularProgress key="progress" radius="60" stroke="4" progress={this.props.progress}/>;
        let description = [];

        if(animation) {
            description.push(animation);
        }

        if(progress) {
            description.push(progress);
        }

        if(this.props.description instanceof Array) {

            this.props.description.map( (desc, index) => {
                description.push(<p key={index}>{desc}</p>);
                return true;
            });
            
        } else {

            if(this.props.description) {
                description.push(<p key="description">{this.props.description}</p>);
            }
        }

        let input;
        let footer;
        let className = "Dialog type-" + type;

        switch(type) {

            case "alert":
            footer = <div className="buttons">{close}</div>;
            break;

            case "confirm":
            footer = <div className="buttons">{confirm}{cancel}</div>;
            break;

            case "prompt-text":
            footer = <div className="buttons">{submit}{cancel}</div>;
            input = <Form.Input autoComplete={this.props.autoComplete} autoFocus type="text" name="prompt" id="prompt" value={this.props.value} placeholder={this.props.placeholder}/>;
            description = input;
            break;

            case "prompt-select":
            footer = <div className="buttons">{submit}{cancel}</div>;
            input = <Form.Input autoFocus type="select" choose={this.props.choose || this.props.lang.get.dialog.choose} name="prompt" value={this.props.value} placeholder={this.props.placeholder} data={this.props.options}/>;
            description = input;
            break;            

            case "progress":
            footer = <div className="buttons">{cancel}</div>;
            break;

            default:
            break;
        }

        const descriptions = <div className={this.props.descriptionMode ? "descriptions mode-"+this.props.descriptionMode : "descriptions"}>{description}</div>;

        return (

            <Modal>
                <Form onSubmit={this.submit} submitOnEnter={true}>
                    <Card className={className} title={this.props.title} subtitle={this.props.subtitle} description={descriptions} footer={footer}/>
                </Form>
            </Modal>

        );

    }
}

export default Dialog;
