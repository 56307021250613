import { observable, action, computed, runInAction } from 'mobx';

export default class UiStore {

    constructor(root) {
        this.root = root;
    }

    @observable isMenuOpen = false;
    @observable isShortcutOpen = false;
    @observable loading = 0;
    @observable location = "";
    @observable isContentVisible = false;
    @observable isFooterVisible = false;
    @observable title = "Doks";
    @observable keyword = "";
    @observable teams_id = "";
    @observable tags_id = "";
    @observable users_id = "";
    @observable riskscore = "";
    @observable alertsViewMode = "alert";
    @observable taskFilters = {};
    @observable modals = [];

    @action.bound setTaskFilters = (filters) => {
        this.taskFilters = filters;
    }

    @action.bound setAlertsViewMode = (mode) => {
        this.alertsViewMode = mode;
    }

    @action.bound setKeyword(keyword) {
        this.keyword = keyword;
    }

    @action.bound resetKeyword() {
        this.setKeyword("");
    }

    @action.bound setTeam(teams_id) {
        this.teams_id = teams_id;
    }

    @action.bound resetTeam() {
        this.setTeam("");
    }

    @action.bound setTag(tags_id) {
        this.tags_id = tags_id;
    }

    @action.bound resetTag() {
        this.setTag("");
    }

    @action.bound setUser(users_id) {
        this.users_id = users_id;
    }

    @action.bound resetUser() {
        this.setUser("");
    }

    @action.bound setRiskscore(level) {
        this.riskscore = level;
    }

    @action.bound resetRiskscore() {
        this.setRiskscore("");
    }

    @action.bound setLoading(cnt) {
    
//        const ms = cnt > 0 ? 500 : 0;
 //       setTimeout(() => {
            this.loading += cnt;
   //     }, ms);
    }

    @action.bound setTitle(title) {
        this.title = title;
        document.title = this.title;
    }

    pushPaths = (url, history) => {

        if(!url || !history) {
            return false;
        }

        let location = history.location && history.location.pathname;
        if(!location) {
            return false;
        }

        if(url === location) {
            return true;
        }

        // Replace current url with desired one
        history.replace(url);

        // Push path by path into location history
        let newLocation = "";
        location.split('/').forEach((path) => {

            if(path) {

                newLocation += "/" + path;

                if(newLocation.indexOf(url) === 0 && newLocation !== url) {           
                    history.push(newLocation);
                }
            }
            
        });
 
        return true;
    }

    @action.bound transition(location) {

        let transition = false;
        // has old location more info than new location?
        if(this.location.length > location.length) {
            if(this.location.indexOf(location) === -1) {
                transition = true;
            }
        }
    
        // has new location more info than old?
        if(location.length > this.location.length) {
            if(location.indexOf(this.location) === -1) {
                transition = true;
            }
        }

        if(this.location === "") {
            transition = true; 
        }

        if(transition) {

            runInAction(() => {

                this.isContentVisible = false;
                setTimeout( () => {

                    runInAction(() => {
                        this.isContentVisible = true;
                    });

                }, 1);
            });

        }

        this.location = location;

    }

    @action.bound toggleMenu() {

        if(this.isMenuOpen) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    @action.bound openMenu() {
        this.isMenuOpen = true;
        this.isPanelOpen = false;
    }

    @action.bound closeMenu() {
        this.isMenuOpen = false;
    }

    @action.bound toggleShortcut() {

        if(this.isShortcutOpen) {
            this.closeShortcut();
        } else {
            this.openShortcut();
        }
    }

    @action.bound openShortcut() {
        this.isShortcutOpen = true;
    }

    @action.bound closeShortcut() {
        this.isShortcutOpen = false;
    }

    @action.bound showFooter() {
        this.isFooterVisible = true;
    }

    @action.bound hideFooter() {
        this.isFooterVisible = false;
    }

    @computed get isLoading() {
        return this.loading > 0;
    }

    @action.bound openModal(modal) {
        this.modals.push(modal);
    }

    @action.bound closeModal(index, e) {
        this.modals.shift();
    }

    @computed get isModalOpen() {
        return this.modals.length > 0;
    }

    @computed get nextModal() {

        if(this.isModalOpen === false) {
            return false;
        }

        return this.modals[0];

    }
}

