import DoksApiStore from './DoksApiStore';
import { observable, action, computed, runInAction } from 'mobx';
import api from 'api.json';

export default class AuthStore extends DoksApiStore {

    constructor(root) {

        super();

        this.root = root;
        this.url = api.endpoints.user.auth;

        runInAction(() => {
            this.jwt = localStorage.getItem("jwt") || "";
            this.id = localStorage.getItem("id") || false;
            this.isSessionResumed = this.id ? true : false;
        });
    }

    @observable jwt = "";
    @observable id = false;
    @observable isSessionResumed = false;    // this flag indicates if user has saved id in localstorage in first place or not

    @action.bound logout() {
        this.setJwt("");
        this.setId(false);
        window.location.href = "/";
    }

    @action.bound setJwt(jwt) {
        this.jwt = jwt;
        localStorage.setItem("jwt", this.jwt);
    }

    @action.bound setId(id) {

        this.id = id;
        if(id) {
            localStorage.setItem("id", this.id);
        } else {
            localStorage.removeItem("id");
        }
    }

    @computed get isLoggedIn() {
        return (this.jwt === "") ? false : true;
    }

    @action.bound async login(email, password, mfauth_password) {

        const res = await this.post(
            {"email": email, "password": password, "mfauth_password": mfauth_password},
            {renew: false}
        );

        if(this.isResponseOk(res)) {

            if(res.data.jwt) {

                const reload = this.id !== false && this.id !== res.data.id;

                this.setJwt(res.data.jwt);
                this.setId(res.data.id);

                if(reload) {
                    window.location.reload(true);
                }

            }
        }

        return res;
    }

    @action.bound async forgot(email) {

        const res = await this.patch(
            {"email": email}
        );

        return res;
    }

    @action.bound async renew() {

        const res = await this.put(
            {},
            {renew: false}
        );

        if(this.isResponseOk(res)) {
            this.setJwt(res.data.jwt);
            this.setId(res.data.id);
        } else {
            this.setJwt("");
            this.setId(false);
        }

        return res;
    }

    @action.bound async temporary() {

        const res = await this.put(
            {"lifetime": 60}
        );

        return res;
    }

}
