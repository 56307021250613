import React, { Component } from 'react';
import Icon from 'components/dumb/Icon/Icon';
import Flex from 'components/dumb/Flex/Flex';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action, runInAction} from 'mobx';
import './Panel.scss';

// Fader
const Fader = (props) => {

    let className = "PanelFader";
    if(props.shadows === false) {
        className += " noShadows";
    }

    return <div style={props.style} onClick={props.onClick} className={className}></div>;
}

// Header
const Header = (props) => {

    const back = (props.back ? <Flex.Column size="min" className="column back on" onClick={props.back}><Icon name="chevron_left"/></Flex.Column> : <Flex.Column size="min" className="column back"/>);
    const title = (props.title ? <Flex.Column size="max" className="column title"><h3>{props.title}</h3></Flex.Column> : <Flex.Column size="min" className="column title"/>);
    const close = (props.close ? <Flex.Column size="min" className="column close on" onClick={props.close}><Icon name="close"/></Flex.Column> : <Flex.Column size="min" className="column close"/>);
    const action =  props.action && <Flex.Column size="min" className="column action on" onClick={props.action}><Icon name={props.actionIcon ? props.actionIcon : "more_vert"}/></Flex.Column>;

    return <Flex align="center" noWrap={true} className="header">{back}{title}{close}{action}</Flex>;

}

// Content
const Content = (props) => {
    return <div className="content">{props.children}</div>;
}

// Loading
const Loading = (props) => {
    return <div className="loading"></div>;
}

// Navi
const Navi = (props) => {

    const icon = props.icon && <Flex.Column size="min"><Icon className="icon" name={props.icon}/></Flex.Column>;
    const title = props.title && <Flex.Column size="max"><h5 className="title">{props.title}</h5></Flex.Column>;
    return <Link to={props.to}><Flex align="center" className="navi">{icon}{title}</Flex></Link>;
}

// Section group
const SectionGroup = (props) => {
        
    let className = "sectiongroup";
    if(props.className) {
        className += " "+props.className;
    }

    return <Flex className={className}>{props.children}</Flex>;
}

// Section
const Section = (props) => {

    const help = props.help && <Icon name="help_outline" className="help" onClick={props.help}/>;
    const title = props.title && <h4 className="title">{props.title} {help}</h4>;
    const subtitle = props.subtitle && <h5 className="subtitle">{props.subtitle}</h5>;
    const titles = (title || subtitle) && <div className="titles">{title}{subtitle}</div>;

    let className = "section";
    if(props.className) {
        className += " "+props.className;
    }
    if(props.noBorders) {
        className += " noBorders";
    }

    return <Flex.Column className={className}>{titles}{props.children}</Flex.Column>;
}

// Title+text
const Pair = (props) => {

    const name = props.name && <h5 className="name">{props.name}</h5>;
    const value = props.value && <p className="value">{props.value}</p>;

    if(props.hideEmpty && !props.value) {
        return null;
    }

    return <div className="pair">{name}{value}</div>;
}

// Status
const Status = (props) => {

    let className = "status";

    if(props.className) {
        className += " " + props.className;
    }

    if(props.status) {
        className += " status-" + props.status;
    }

    const text = props.text && <h5 className="text">{props.text}</h5>;
    return <div className={className}>{text}</div>;

}

// Delimiter
const Delimiter = (props) => {
    return <hr className="delimiter"/>;
}

// Section with toggle
@observer
class SectionWithToggle extends Component {
    
    @observable show = false;
    static Section = Section;

    toggle = (v) => {

        runInAction(() => {
            this.show = !this.show;  
            this.props.onToggle && this.props.onToggle(this.show);
        });
    }

    componentDidMount = () => {

        if(this.props.show) {
            runInAction(() => {
                this.show = true;
            });
        }
    }

    render() {

        const icon = this.show === true ? <Flex.Column size="min"><Icon className="toggle toggled" name="toggle_on"/></Flex.Column> : <Flex.Column size="min"><Icon className="toggle" name="toggle_off"/></Flex.Column>; 
        const text = this.props.text && <Flex.Column size="max"><span className="label">{this.props.text}</span></Flex.Column>;
        const content = <div className="SectionWithToggleContent">{(this.show === true || this.props.hide)  && this.props.children}</div>
        const className = this.show === true ? "SectionWithToggle toggled" : "SectionWithToggle";

        return (
            <Section className={className}>
                <Flex align="center" noWrap className="Toggle" onClick={this.toggle}>{icon} {text}</Flex>
                {content}   
            </Section>
        );
    }
}

// Panel
@observer
class Panel extends Component {

    static Fader = Fader;
    static Content = Content;
    static Loading = Loading;
    static Header = Header;
    static Navi = Navi;
    static SectionGroup = SectionGroup;
    static Section = Section;
    static Pair = Pair;
    static Status = Status;
    static Delimiter = Delimiter;
    static SectionWithToggle = SectionWithToggle;

    @observable isOpen = false;
    @observable wait = false;

    @action open = () => {

        setTimeout( () => {
            runInAction(() => {
                this.isOpen = true;
            });
        }, 0);
    }

    @action close = (callback) => {

        this.isOpen = false;

        if(callback) {

            this.wait = true;
            setTimeout( () => {
                callback();
                runInAction(() => {
                    this.wait = false;
                });
            }, 250);
        }
    }

    onClick = () => {
        this.close(this.props.onClick);
    }

    onClose = () => {
        this.close(this.props.onClose);
    }

    onBack = () => {
        this.close(this.props.onBack);
    }

    onAction = () => {
        this.props.onAction();
    }

    componentDidMount() {
        this.props.open && this.open();    
    }

    componentDidUpdate() {
        this.props.open && !this.wait && this.open();
        !this.props.open && this.close();
    }

    render() {

        let className = "Panel";

        if(this.props.className) {
            className += ' ' + this.props.className;
        }

        if(this.props.size) {
            className += ' ' + this.props.size;
        }

        if(this.props.position) {
            className += ' ' + this.props.position;
        }

        if(this.isOpen) {
            className += ' open';
        }

        if(this.props.title) {
            className += ' title';
        }

        if(this.props.shadows === false) {
            className += ' noShadows';
        }

        let zstyle = this.props.z && {zIndex: 1000 + this.props.z};

        // Fader
        const panelFader = this.isOpen && <Panel.Fader shadows={this.props.shadows} style={zstyle} onClick={this.props.onClick && this.onClick}/>;

        // Header
        const panelHeader = this.props.title && <Panel.Header title={this.props.title} close={this.props.onClose && this.onClose} back={this.props.onBack && this.onBack} action={this.props.onAction && this.onAction} actionIcon={this.props.actionIcon}/>;

        // Loading
        const panelLoading = this.props.loading && <Panel.Loading/>;

        // Content
        const panelContent = <Panel.Content>{this.props.children}</Panel.Content>;

        // Panel
        const panel = <div id={this.props.idName} style={zstyle} className={className}>{panelHeader}{panelLoading}{panelContent}</div>;

        // Return as fragment
        return (<React.Fragment>
                    {panelFader}
                    {panel}
                </React.Fragment>);

    }

}

export default Panel;

/*
@observer
class Input extends Component {

    @observable value = "";

    @action change = (e) => {
        this.value = e.target.value;
        th

class Form extends Component {

    static Input = Input;
    static Submit = Submit;

    change = (e) => {

        this.props.onChange && this.props.onChange(e.target, e.target.value);
    }

    submit = (e) => {

        e.preventDefault();
        this.props.onSubmit && this.props.onSubmit(this.values(e));
    }

    values = (e) => {

        let elements = e.target.elements;
        let data = {};

        for (var i = 0; i < elements.length; i++) {

            if(elements[i].name) {
                data[elements[i].name] = elements[i].value;
            }
        }

        return data;
    }

    keydown = (e) => {

        if(e.key === "Enter" && this.props.onKeyDownEnter) {
            this.props.onKeyDownEnter(e.target, e.target.value);
        }

        this.props.onKeyDown && this.props.onKeyDown(e.target, e.key, e.target.value);
    }

    render() {
        return <form id={this.props.id} onChange={this.change} onSubmit={this.submit} onKeyDown={this.keydown}>{this.props.children}</form>;
    }
}

export default Form;













// Panel
const Panel = (props) => {

    let className = "Panel";

    if(props.size) {
        className += ' ' + props.size;
    }

    if(props.position) {
        className += ' ' + props.position;
    }

    if(props.open) {
        className += ' open';
    }

    if(props.title) {
        className += ' title';
    }

    // Fader
    const panelFader = props.open && <Panel.Fader onClick={props.onClick}/>;

    // Header
    const panelHeader = props.title && <Panel.Header title={props.title} close={props.close} back={props.back}/>;

    // Content
    const panelContent = <Panel.Content>{props.children}</Panel.Content>;

    // Panel
    const panel = <div id={props.idName} className={className}>{panelHeader}{panelContent}</div>;

    // Return as fragment
    return (<React.Fragment>
                {panelFader}
                {panel}
            </React.Fragment>);
                
}

// Fader
Panel.Fader = (props) => {
    return <div onClick={props.onClick} className="PanelFader"></div>;
}

// Header
Panel.Header = (props) => {

    const title = props.title && <div className="column title"><h3>{props.title}</h3></div>;
    const close = props.close && <div className="column close" onClick={props.close}><Icon name="close"/></div>;
    const back = props.back && <div className="column back" onClick={props.back}><Icon name="arrow_left"/></div>;

    return <div className="header">{back}{title}{close}</div>;

}

// Content
Panel.Content = (props) => {
    return <div className="content">{props.children}</div>;
}

// Link
Panel.Link = (props) => {

    const icon = props.icon && <Icon className="icon" name={props.icon}/>;
    const title = props.title && <h5 className="title">{props.title}</h5>;

    return <Link to={props.to}><div className="link">{icon}{title}</div></Link>;
}

export default Panel;

*/
